import main_frame from "./assets/main_page.png";
import logo from "./assets/logo_byn.png";
import graph from "./assets/graph_new.png";
import phone from "./assets/phone.jpg";
import React from "react";
import { FirebaseApp, initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useState } from "react";

// Your web app's Firebase configuration
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyDNmLZ7KxtT7Ac4k1p3Dd7km58sn0K1G3I",
  authDomain: "fleep-19376.firebaseapp.com",
  projectId: "fleep-19376",
  storageBucket: "fleep-19376.appspot.com",
  messagingSenderId: "416328883722",
  appId: "1:416328883722:web:eeb855ba54bc25dcfa89f3",
};

let app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function App() {
  let [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const signupRef = React.useRef<HTMLDivElement>(null);

  const handleSignUp = async () => {
    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email format");
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "users"), { email });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleContactUsClick = () => {
    if (signupRef.current) {
      signupRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="App">
      <header className="w-full bg-white h-16 flex justify-center items-center px-4 shadow-md">
        <div className="w-full md:w-1/2 flex justify-between items-center px-4 md:px-0">
          <img className="w-20" src={logo} alt="logo" />
          <button
            className="bg-blue-500 text-white rounded p-2"
            onClick={handleContactUsClick}
          >
            Contact us
          </button>
        </div>
      </header>
      <body className="bg-gray-100 min-h-screen px-4 md:px-0 pt-10 pb-20">
        <div className="flex items-center justify-center md:px-0">
          <div>
            <h1 className="text-4xl md:text-5xl font-bold pt-10 pb-5 text-center">
              Reselling inventory tool & data
              <br /> provider for resellers
            </h1>
            <p className="text-center">
              Streamline inventory management, leverage our tailored e-commerce
              solutions
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center p-4 md:p-20">
          <img
            className="rounded-xl w-full md:w-full lg:w-1/2"
            src={main_frame}
            alt="main_frame"
          />
        </div>
        <h1 className="text-4xl md:text-5xl font-bold pt-20 pb-5 text-center">
          AI vision scanning for inventory <br /> management
        </h1>
        <p className="text-center">
          Add new products easily with our AI Vision tools <br /> that can scan
          items and add them to your inventory automatically
        </p>
        <h1 className="text-4xl md:text-5xl font-bold pt-20 pb-5 text-center">
          Realtime inventory and market <br />
          opportunities analytics
        </h1>
        <p className="text-center">
          Streamline inventory analytics and selling optimizations
          visualizations tools{" "}
        </p>

        <div className="flex items-center justify-center p-4 md:p-20">
          <img
            className="rounded-xl w-full md:w-3/4 lg:w-1/2"
            src={graph}
            alt="graph"
          />
        </div>

        {/* <div className="flex items-center justify-center p-4 md:p-20">
          <img
            className="rounded-xl w-full md:w-3/4 lg:w-1/2"
            src={phone}
            alt="phone"
          />
        </div> */}
        <h1 className="text-4xl md:text-5xl font-bold pt-20 pb-5 text-center">
          Sign Up to get to know more
        </h1>
        <p className="text-center">
          Sign up and we will email you to schedule some time to talk about the
          platofrm
        </p>
        <div className="flex justify-center" ref={signupRef}>
          <form className="w-full md:max-w-md flex flex-col sm:flex-row items-center">
            <div className="p-5 flex-grow">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline self-bottom sm:self-auto whitespace-nowrap"
              type="button"
              onClick={handleSignUp}
            >
              Sign Up
            </button>
            {error && <p>{error}</p>}
          </form>
        </div>
      </body>
      <footer className="w-full p-4 text-center">
        <p className="mb-4">leon.tadina@fleep.es</p>
        <div className="flex justify-center items-center space-x-4">
          <a
            href="https://www.instagram.com/your_instagram_page/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="path_to_your_instagram_icon" alt="Instagram" />
          </a>
          <a
            href="https://twitter.com/your_twitter_page"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="path_to_your_twitter_icon" alt="Twitter" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
